export const Helper = {
    data() {
        return {
            message: 'Hello from mixin!'
        };
    },
    methods: {
        InstitutionName(institutionNumber) {
            const bankMap = {
                "001": "Bank of Montreal (BMO)",
                "002": "The Bank of Nova Scotia (Scotiabank)",
                "003": "Royal Bank of Canada (RBC)",
                "004": "The Toronto-Dominion Bank (TD)",
                "006": "National Bank of Canada (NBC)",
                "010": "Canadian Imperial Bank of Commerce (CIBC)",
                "016": "HSBC Bank Canada",
                "030": "Canadian Western Bank",
                "039": "Laurentian Bank of Canada",
                "117": "President's Choice Financial",
                "127": "Manulife Bank of Canada",
                "140": "ICICI Bank Canada",
                "147": "Ally Credit Canada",
                "159": "Vancity Savings Credit Union",
                "162": "Community Trust Company",
                "177": "Royal Trust Company",
                "219": "First Nations Bank of Canada",
                "265": "TD Canada Trust",
                "285": "President's Choice Bank",
                "300": "ING Bank of Canada",
                "309": "EQ Bank",
                "314": "General Bank of Canada",
                "320": "Canadian Tire Bank",
                "338": "Motus Bank",
                "339": "Motive Financial",
                "340": "Bridgewater Bank",
                "350": "Alterna Savings and Credit Union",
                "368": "Meridian Credit Union",
                "370": "Conexus Credit Union",
                "371": "Assiniboine Credit Union",
                "382": "First West Credit Union",
                "389": "Prospera Credit Union",
                "394": "Servus Credit Union",
                "397": "DUCA Financial Services Credit Union",
                "401": "Valley First Credit Union",
                "410": "Interior Savings Credit Union",
                "420": "Island Savings Credit Union",
                "509": "Wealth One Bank of Canada",
                "588": "Haventree Bank",
                "589": "Simplii Financial",
                "614": "Tangerine Bank",
                "809": "Bridge Credit Union",
                "815": "Federation Des Caisses Desjardins",
                "819": "Coast Capital Savings Credit Union",
                "828": "Community Savings Credit Union",
                "839": "First Calgary Financial Credit Union",
                "843": "Northern Savings Credit Union",
                "845": "Northern Credit Union",
                "899": "SaskCentral Credit Union"
            };

            return bankMap[institutionNumber] || "Unknown institution number";
        }
    }
};

import api from './Api';

const BaseURL = 'bank-account';

export const RetrieveBankAccounts = async (start_at = null, end_at = null, page = 1) =>
{
    if (start_at && end_at) {
        return await api().get(`${BaseURL}?start_at=${start_at}&end_at=${end_at}&page=${page}`);
    } else {
        return await api().get(`${BaseURL}?page=${page}`);
    }

};

export const RetrieveBankAccount = async (id) =>
{
    return await api().get(`${BaseURL}/${id}`);
};

export const SearchBankAccount = async (flinks_login_id) =>
{
    return await api().get(`/v2/bank-account/${flinks_login_id}`);
};

<script setup>

</script>

<template>
    <div>
        forgotpassword
    </div>
</template>

<style scoped>

</style>

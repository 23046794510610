import api from './Api';

const BaseURL = 'auth';

export const InitiateAuth = async (FormData) =>
{
    return await api().post(`${BaseURL}/initiate-auth`, FormData);
};

export const AuthChallenge = async (FormData) =>
{
    return await api().post(`${BaseURL}/auth-challenge`, FormData);
};

export const AuthRefresh = async () =>
{
    return await api().post(`${BaseURL}/refresh`);
};

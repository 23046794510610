import { createRouter, createWebHistory} from "vue-router";

import LoginPage from "../pages/LoginPage.vue";
import ForgotPasswordPage from "../pages/ForgotPasswordPage.vue";
import BankAccountPage from "../pages/BankAccountPage.vue";
import DashboardPage from "../pages/DashboardPage.vue";
import store from "../store/index.js";
import {RetrieveContextUser} from "../services/User.js";
import BankAccountsPage from "../pages/BankAccountsPage.vue";
import { AuthRefresh } from "../services/Authentication.js";

const routes = [
    {
        path: "/",
        component: DashboardPage,
        name: "dashboard",
        meta: {
            authOnly: true,
        }
    },
    {
        path: "/login",
        component: LoginPage,
        name: "login",
        meta: {
            guestOnly: true,
        }
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPasswordPage,
    },
    {
        path: "/bank-account/:id",
        name: "bank-account",
        component: BankAccountPage,
        meta: {
            authOnly: true,
        }
    },
    {
        path: "/bank-account",
        name: "bank-account-by-logid",
        component: BankAccountPage,
        meta: {
            authOnly: true,
        }
    },
    {
        path: "/bank-accounts",
        name: "bank-accounts",
        component: BankAccountsPage,
        meta: {
            authOnly: true,
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => 
{
    await RetrieveContextUser()
        .then(response => {
            store.state.ContextUser = response.data.user;
        })
        .catch(error => {
            AuthRefresh()
                .then(response => {
                    if (response.status === 200) {
                        window.location.href = '/'
                    }
                })
                .catch(() => {
                    if (window.location.pathname !== '/login') {
                        window.location.href = '/login';
                    }
                })
        })

    const isLoggedIn = store.state.ContextUser !== null;

    if (to.matched.some(record => record?.meta?.authOnly)) {
        !isLoggedIn
            ? next({path: "/login"})
            : next();
    } else if (to.matched.some(record => record?.meta?.guestOnly)) {
        !!isLoggedIn
            ? next({path: "/"})
            : next();
    } else {
        next();
    }
});

export default router;

<template>
    <auth-layout>
            <div class="mt-[18px] flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="custom-shadow-1 h-fit mb-[24px] overflow-hidden">
                            <table class="min-w-full divide-y divide-gray-100">
                                <thead class="bg-gray-50">
                                    <tr class="divide-x divide-gray-100">
                                        <th scope="col" class="font-normal py-3.5 pl-4 pr-3 text-left text-[10.5px] text-gray-500 tracking-wide">Client</th>
                                        <th scope="col" class="w-[155px] max-w-[205px] font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Phone Number</th>
                                        <th scope="col" class="w-[155px] max-w-[205px] font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Accounts</th>
                                        <th scope="col" class="w-[155px] max-w-[205px] font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Source</th>
                                        <th scope="col" class="w-fit font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Institution</th>
                                        <th scope="col" class="w-fit max-w-[205px] font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Last Update</th>
                                        <th scope="col" class="w-fit max-w-[205px] font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Checks</th>
                                    </tr>
                                </thead>
                                <tbody v-if="bank_accounts.length > 0" class="divide-y divide-gray-200 bg-white">
                                    <tr
                                        v-for="(Account, Index) in bank_accounts"
                                        :key="Index"
                                        @click="Redirect(Account.id)"
                                        class="cursor-pointer divide-x divide-gray-100 curs"
                                    >
                                        <td class="h-[68px] w-full whitespace-nowrap py-4 px-4 text-[12px] font-normal text-gray-900">
                                            <div class="flex flex-col">
                                                <span class="text-[12px] capitalize">{{ Account?.bank_accounts[0]?.bank_account_holder?.name }}</span>
                                                <span class="text-[10.5px] text-gray-400">ID: {{ Account?.reference_id }}</span>
                                            </div>
                                        </td>
                                        <td class="h-[68px] max-w-[105px] whitespace-nowrap text-center px-3 py-4 text-[10.5px] text-gray-500">{{ `(${Account?.bank_accounts[0]?.bank_account_holder?.phone_regional_code ? (Account?.bank_accounts[0]?.bank_account_holder?.phone_regional_code) : ''}) ${Account?.bank_accounts[0]?.bank_account_holder?.phone_number ? ((Account?.bank_accounts[0]?.bank_account_holder?.phone_number).toString().slice(0, 3) + '-' + (Account?.bank_accounts[0]?.bank_account_holder?.phone_number).toString().slice(3)) : ''}` }}</td>
                                        <td class="h-[68px] max-w-[105px] whitespace-nowrap text-center px-3 py-4 text-[10.5px] text-gray-500">{{ Account?.bank_accounts.length }}</td>
                                        <td class="h-[68px] max-w-[105px] whitespace-nowrap text-center px-3 py-4 text-[10.5px] text-gray-500">
                                            <span class="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-[10.5px] font-medium text-blue-700">
                                                {{ Account?.provider }}
                                            </span>
                                        </td>
                                        <td class="h-[68px] whitespace-nowrap px-3 py-4 text-[10.5px] tracking-wide text-center text-gray-500">{{ this.InstitutionName(Account?.bank_accounts[0]?.institution_number) }}</td>
                                        <td class="h-[68px] whitespace-nowrap text-center px-3 py-4 text-[10.5px] text-gray-500">
                                            {{
                                                `${new Date(Account.last_refresh * 1000).toISOString().slice(0, 10).replace(/-/g, '-')} ${new Date(Account.last_refresh * 1000).toTimeString().slice(0, 8).replace(/:/g, ':')}`
                                            }}
                                        </td>
                                        <td class="whitespace-nowrap h-[68px] text-[10.5px] tracking-wide flex items-center justify-center text-center text-gray-500">
                                            <div class="w-[42px] h-[42px] bg-pink-100 rounded-[4pt] flex items-center justify-center">
                                                <div
                                                    class="w-[24px] h-[24px] border-pink-600 border rounded-full flex items-center justify-center"
                                                >

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="6" class="bg-white h-[75px]">
                                            <div class="w-full h-full flex items-center justify-center text-[10.5px] text-gray-400 tracking-wide">
                                                No data available
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
        </div>
    </auth-layout>
</template>

<script>
import {RetrieveBankAccounts} from "../services/BankAccount.js";
import AuthLayout from "../components/Common/AuthLayout.vue";
import {mapState} from "vuex";
import {Helper} from "../mixins/Helper.js";

export default
{
    mixins: [Helper],

    data() {
        return {
            bank_accounts: [],
            is_loading: false,
        }
    },

    components: {
        AuthLayout
    },

    computed: {
        ...mapState([
            'ContextUser'
        ]),
    },

    methods: {
        Redirect(id) {
            window.location.href = `bank-account/${id}`;
        },
    },

    mounted() {
        this.is_loading = true;

        const currentDate = new Date();
        const timezoneOffset = currentDate.getTimezoneOffset() * 60000;
        const localDate = new Date(currentDate.getTime() - timezoneOffset);

        const startOfDay = new Date(localDate.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfDay = new Date(localDate.setHours(23, 59, 59, 999)).getTime() / 1000;

        RetrieveBankAccounts(startOfDay, endOfDay)
            .then(response => {
                this.bank_accounts = response.data.bank_account_clients;
            })
        this.is_loading = false;
    }
}
</script>

<style scoped>

</style>

<template>
    <router-view></router-view>
</template>

<script>
import {AuthRefresh} from "./services/Authentication.js";

export default
{
    data() {
        return {
            isRefreshing: false,
        }
    },

    methods: {
        GetCookieValue(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        },

        checkExpiration() {
            const cookieValue = this.GetCookieValue('X-EXP');
            if (cookieValue && !this.isRefreshing) {
                this.isRefreshing = true;
                const currentUnixTime = Math.floor(Date.now() / 1000);
                if (parseInt(cookieValue) <= (currentUnixTime + 300)) {
                    AuthRefresh()
                }
            }
            this.isRefreshing = false;
        },
    },

    mounted() {
        setInterval(this.checkExpiration, 5000);
    }
}
</script>

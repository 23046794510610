<script>
export default {
    name: "BankAccountChecks",
    props: {
        check: null
    }
}
</script>

<template>
    <div>
        <span v-if="check === true" class="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-[9.5px] font-medium text-green-600 tracking-wide">
            <svg class="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                <circle cx="3" cy="3" r="3" />
            </svg>
            Passed
        </span>
        <span v-else-if="check === false" class="inline-flex items-center gap-x-1.5 rounded-md bg-pink-100 px-2 py-1 text-[9.5px] font-medium text-pink-600 tracking-wide">
            <svg class="h-1.5 w-1.5 fill-pink-500" viewBox="0 0 6 6" aria-hidden="true">
              <circle cx="3" cy="3" r="3" />
            </svg>
            Failed
        </span>
        <span v-else class="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-[9.5px] font-medium text-gray-500 tracking-wide">
            <svg class="h-1.5 w-1.5 fill-gray-400" viewBox="0 0 6 6" aria-hidden="true">
                <circle cx="3" cy="3" r="3" />
            </svg>
            Pending
        </span>
    </div>
</template>

<style scoped>

</style>

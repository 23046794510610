<template>
    <div class="flex items-center bg-theme-gray justify-center h-screen w-screen">
        <div class="absolute bottom-0 h-[45px] text-gray-500 font-light tracking-wide text-[9.5px] flex items-center justify-center">
            &copy; 2023-2024 GESTION PPA
        </div>
        <div class="px-[14pt] flex items-center justify-between h-[55px] w-screen fixed top-0 left-0">
            <span class="flex h-[38px] bg-theme-purple bg-opacity-10 text-[12.5px] text-theme-purple items-center rounded-[4pt] tracking-wide font-medium">
                <img class="h-[28px] pl-[6px]" src="/assets/logo_purple_reversed.svg" />
                <span class="w-full px-[24px]">
                    Automation
                </span>
            </span>
            <div v-click-outside="CloseMenu" class="relative">
                <span @click="MenuIsOpen = !MenuIsOpen" class="icon-container | hover:bg-gray-200 w-[28px] h-[28px] flex items-center justify-center rounded-lg duration-500 cursor-pointer">
                    <svg id="menu-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24">
                        <rect width="4.5" height="4.5" x="3" y="3" fill="#9ca3af" rx="1"/>
                        <rect width="4.5" height="4.5" x="9.75" y="3" fill="#9ca3af" rx="1"/>
                        <path fill="#9ca3af" d="M16.5 4.61c0-.57 0-.854.113-1.071a1 1 0 0 1 .426-.426C17.256 3 17.541 3 18.11 3h1.28c.57 0 .854 0 1.071.113a1 1 0 0 1 .426.426C21 3.756 21 4.041 21 4.61v1.28c0 .57 0 .854-.113 1.071a1 1 0 0 1-.426.426c-.217.113-.502.113-1.071.113h-1.28c-.57 0-.854 0-1.071-.113a1 1 0 0 1-.426-.426c-.113-.217-.113-.502-.113-1.071V4.61Z"/>
                        <rect width="4.5" height="4.5" x="3" y="9.75" fill="#9ca3af" rx="1"/>
                        <path fill="#9ca3af" d="M9.75 11.36c0-.57 0-.854.113-1.071a1 1 0 0 1 .426-.426c.217-.113.502-.113 1.071-.113h1.28c.57 0 .854 0 1.071.113a1 1 0 0 1 .426.426c.113.217.113.502.113 1.071v1.28c0 .57 0 .854-.113 1.071a1 1 0 0 1-.426.426c-.217.113-.502.113-1.071.113h-1.28c-.57 0-.854 0-1.071-.113a1 1 0 0 1-.426-.426c-.113-.217-.113-.502-.113-1.071v-1.28Zm6.75 0c0-.57 0-.854.113-1.071a1 1 0 0 1 .426-.426c.217-.113.502-.113 1.071-.113h1.28c.57 0 .854 0 1.071.113a1 1 0 0 1 .426.426c.113.217.113.502.113 1.071v1.28c0 .57 0 .854-.113 1.071a1 1 0 0 1-.426.426c-.217.113-.502.113-1.071.113h-1.28c-.57 0-.854 0-1.071-.113a1 1 0 0 1-.426-.426c-.113-.217-.113-.502-.113-1.071v-1.28Z"/>
                        <rect width="4.5" height="4.5" x="3" y="16.5" fill="#9ca3af" rx="1"/>
                        <path fill="#9ca3af" d="M9.75 18.11c0-.57 0-.854.113-1.071a1 1 0 0 1 .426-.426c.217-.113.502-.113 1.071-.113h1.28c.57 0 .854 0 1.071.113a1 1 0 0 1 .426.426c.113.217.113.502.113 1.071v1.28c0 .57 0 .854-.113 1.071a1 1 0 0 1-.426.426c-.217.113-.502.113-1.071.113h-1.28c-.57 0-.854 0-1.071-.113a1 1 0 0 1-.426-.426c-.113-.217-.113-.502-.113-1.071v-1.28Zm6.75 0c0-.57 0-.854.113-1.071a1 1 0 0 1 .426-.426c.217-.113.502-.113 1.071-.113h1.28c.57 0 .854 0 1.071.113a1 1 0 0 1 .426.426c.113.217.113.502.113 1.071v1.28c0 .57 0 .854-.113 1.071a1 1 0 0 1-.426.426c-.217.113-.502.113-1.071.113h-1.28c-.57 0-.854 0-1.071-.113a1 1 0 0 1-.426-.426c-.113-.217-.113-.502-.113-1.071v-1.28Z"/>
                    </svg>
                </span>
                <transition name="pop">
                    <div v-if="MenuIsOpen" class="pop-box custom-shadow-1 | py-[10px] gap-[12px] flex flex-col px-[12px] rounded-[8pt] w-[400px] absolute bg-white right-0 mt-[12px]">
                        <div class="hover:bg-theme-blue hover:border-opacity-50 hover:border-theme-blue border-gray-100 border gap-[12px] cursor-pointer duration-500 hover:bg-opacity-20 rounded-[4pt] flex items-center p-[12px]">
                            <img class="h-[28px]" src="/assets/logo_white_rev.svg" />
                            <div class="flex flex-col">
                                <div class="flex gap-[8px] text-[12.5px] items-center font-medium">
                                    CRM
                                    <div class="text-[9.5px] h-full bg-gray-200 px-[8px] rounded-sm mb-[2px] text-gray-600">Under Construction</div>
                                </div>
                                <span class="text-[11px] -mt-0.5 text-gray-400 tracking-wide">Client & Loan Management Software Service</span>
                            </div>
                        </div>
                        <div class="hover:bg-theme-brown hover:border-opacity-50 hover:border-theme-brown border-gray-100 border gap-[12px] cursor-pointer duration-500 hover:bg-opacity-20 rounded-[4pt] flex items-center p-[12px]">
                            <img class="h-[28px]" src="/assets/logo_brown_reversed.svg" />
                            <div class="flex flex-col">
                                <div class="flex gap-[8px] text-[12.5px] items-center font-medium">
                                    Phone Up
                                    <div class="text-[9.5px] h-full bg-gray-200 px-[8px] rounded-sm mb-[2px] text-gray-600">Under Construction</div>
                                </div>
                                <span class="text-[11px] -mt-0.5 text-gray-400 tracking-wide">External Communication Software Service</span>
                            </div>
                        </div>
                        <div class="hover:bg-theme-green hover:border-opacity-50 hover:border-theme-green border-gray-100 border gap-[12px] cursor-pointer duration-500 hover:bg-opacity-20 rounded-[4pt] flex items-center p-[12px]">
                            <img class="h-[28px]" src="/assets/logo_green_reverse.svg" />
                            <div class="flex flex-col">
                                <div class="flex gap-[8px] text-[12.5px] items-center font-medium">
                                    Collection
                                    <div class="text-[9.5px] h-full bg-gray-200 px-[8px] rounded-sm mb-[2px] text-gray-600">Under Construction</div>
                                </div>
                                <span class="text-[11px] -mt-0.5 text-gray-400 tracking-wide">Loan Payment Collection Software Service</span>
                            </div>
                        </div>
                        <div class="hover:bg-theme-fushia hover:border-opacity-50 hover:border-theme-fushia border-gray-100 border gap-[12px] cursor-pointer duration-500 hover:bg-opacity-20 rounded-[4pt] flex items-center p-[12px]">
                            <img class="h-[28px]" src="/assets/logo_fushia_reversed.svg" />
                            <div class="flex flex-col">
                                <div class="flex gap-[8px] text-[12.5px] items-center font-medium">
                                    Marketing
                                    <div class="text-[9.5px] h-full bg-gray-200 px-[8px] rounded-sm mb-[2px] text-gray-600">Under Construction</div>
                                </div>
                                <span class="text-[11px] -mt-0.5 text-gray-400 tracking-wide">Marketing Software Service</span>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <form v-if="Step === 1" @submit.prevent class="pop-box custom-shadow-1 | w-[450px] mx-auto bg-white h-fit min-h-[100px] rounded-[12pt] pt-[18px] pb-[28px] p-[16px] flex flex-col gap-[14px]">
            <div class="h-[75px] w-full flex flex-col items-center justify-center -mb-1.5 text-[24px] font-medium">
                <span>Welcome Back</span>
                <div class="text-[12px] text-[#AEAEAE] flex">
                    Enter your account details.
                </div>
            </div>
            <div class="flex w-full h-fit">
                <div class="flex flex-col mx-auto w-[385px] h-[55px] rounded-[8pt] items-end gap-1.5">
                    <div
                        :class="errors.username ? 'border-red-400 bg-red-200 bg-opacity-20 border-opacity-75' : 'border-[#D2D2D2] border-opacity-30 bg-white'"
                        class="w-full flex h-[55px] items-center border-2 rounded-[10px] duration-500"
                    >
                        <div
                            :class="errors.username ? 'border-red-400 border-opacity-75' : 'border-opacity-40 border-[#D2D2D2]'"
                            class="pl-[22.5px] pr-[15.5px] h-[30px] flex items-center justify-center border-r-2"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="13" fill="none" viewBox="0 0 20 16">
                                <path :fill="errors.password ? '#dc2626' : '#818181'" d="M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2Zm0 14H2V4l8 5 8-5v10Zm-8-7L2 2h16l-8 5Z"/>
                            </svg>
                        </div>

                        <div class="flex justify-center pl-[20px] w-full pr-[12px] flex-col">
                                    <span
                                        :class="errors.username ? 'text-red-600 text-opacity-75' : 'text-[#818181]'"
                                        class="h-[18px] text-[9.5px] font-medium tracking-wide"
                                    >
                                        Email Address
                                    </span>
                            <input
                                type="text"
                                :class="errors.username ? 'text-red-600' : 'text-black'"
                                class="bg-transparent -mt-1 border-none tracking-wide h-fit w-full text-[11.5px] focus:ring-0 focus:outline-none"
                                v-model="InitiateAuthForm.username"
                                placeholder="example@gestionkronos.com"
                            />
                        </div>
                    </div>
                    <!--                    <span v-if="errors.username" class="text-red-600 text-[10.5px] font-medium tracking-wide">-->
                    <!--                        {{ errors.username_message }}-->
                    <!--                    </span>-->
                </div>
            </div>
            <div class="flex w-full h-fit">
                <div class="flex flex-col mx-auto w-[385px] h-[55px] rounded-[8pt] items-end gap-1.5">
                    <div
                        :class="errors.password ? 'border-red-400 bg-red-200 bg-opacity-20 border-opacity-75' : 'border-[#D2D2D2] border-opacity-30 bg-white'"
                        class="w-full flex h-[55px] items-center border-2 rounded-[10px] duration-500"
                    >
                        <div
                            :class="errors.password ? 'border-red-400 border-opacity-75' : 'border-opacity-40 border-[#D2D2D2]'"
                            class="pl-[22.5px] pr-[15.5px] h-[30px] flex items-center justify-center border-r-2"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 24 24">
                                <g clip-path="url(#a)">
                                    <path :fill="errors.password ? '#dc2626' : '#818181'" d="M21 10h-8.35A5.99 5.99 0 0 0 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6a5.99 5.99 0 0 0 5.65-4H13l2 2 2-2 2 2 4-4.04L21 10ZM7 15c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3Z"/>
                                </g>
                                <defs>
                                    <clipPath id="a">
                                        <path :fill="errors.password ? '#dc2626' : '#fff'" d="M0 0h24v24H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div class="flex justify-center pl-[20px] w-full pr-[12px] flex-col">
                                    <span
                                        :class="errors.password ? 'text-red-600 text-opacity-75' : 'text-[#818181]'"
                                        class="h-[18px] text-[9.5px] font-medium tracking-wide"
                                    >
                                        Password
                                    </span>
                            <input
                                type="password"
                                :class="errors.password ? 'text-red-600' : 'text-black'"
                                class="bg-transparent -mt-1 border-none tracking-wide h-fit w-full text-[11.5px] focus:ring-0 focus:outline-none"
                                v-model="InitiateAuthForm.password"
                                placeholder="password"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="false">
                <router-link
                    to="forgot-password"
                    class="text-[10.5px] float-right h-[24px] hover:bg-black hover:bg-opacity-10 hover:text-black duration-500 px-[14px] rounded-4 flex items-center justify-center pt-0.5 text-gray-500"
                >
                    Forgot Password ?
                </router-link>
            </div>
            <div
                @click="this.InitiateAuthRequest()"
                class="flex items-center font-medium tracking-wide mx-auto justify-center pt-0.5 focus:outline-0 focus:border-0 focus:bg-opacity-100 text-white text-[11.5px] cursor-pointer duration-500 h-[45px] w-[385px] bg-theme-purple rounded-[8pt]"
            >
                        <span v-if="!IsLoggingIn">
                            Continue
                        </span>
                <svg v-else class="animate-spin" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 96 96">
                    <path fill="#fff" d="M44 8v9l-2.125.664C31.544 21.117 25.579 25.1 20.129 34.633c-3.347 7.018-3.238 15.848-1.41 23.297C22.269 67.348 28.113 72.564 37 77c8.194 2.805 18.173 1.828 25.89-1.816 7.138-4.005 12.097-10.303 14.758-18.078 1.835-10.986.271-20.112-5.773-29.356C66.188 22.31 59.687 18.671 52 17V8c11.517.557 20.253 5.497 28 14 7.964 10.88 9.17 21.907 8 35-2.126 10.759-8.799 19.166-17.582 25.492-10.507 6.552-22.724 7.255-34.703 4.848-11.133-3.617-19.438-11.61-25.035-21.727-4.295-9.806-4.415-22.526-1.117-32.675 4.256-9.72 11.613-17.77 21.28-22.258C35.388 8.933 39.098 7.78 44 8Z"/>
                </svg>
            </div>
        </form>
        <transition name="pop">
            <form
                v-if="Step === 2"
                @submit.prevent
                class="pop-box custom-shadow-1 | w-[450px] mx-auto bg-white h-fit min-h-[100px] rounded-[12pt] pt-[18px] pb-[28px] p-[16px] flex flex-col gap-[14px]"
            >
                <div class="h-[75px] w-full flex flex-col items-center justify-center -mb-1.5 text-[24px] font-medium">
                    <span class="capitalize">Hi, {{ InitiateAuthForm.username ? InitiateAuthForm.username.split('@')[0] : '' }}</span>
                    <div class="text-[12px] text-center max-w-[325px] text-[#AEAEAE] flex">
                        Enter the 6-digit code to continue.
                    </div>
                </div>
                <div class="flex w-full h-fit">
                    <div class="flex w-[385px] mx-auto justify-around items-end gap-1.5 ">
                        <div
                            v-for="(value, index) in 6"
                            @click="this.FocusTOTP()"
                            :class="[
                                    index === 2 ? 'mr-[24px]' : '',
                                    errors.totp ? 'border-red-500 border bg-red-100 text-red-600' : '',
                                    AuthChallengeForm.totp.length === value - 1 ? 'border-theme-purple bg-theme-purple bg-opacity-10 text-theme-purple' : 'text-opacity-75 border-[#D2D2D2] bg-white',
                                ]"
                            class="h-[64px] w-full border-2 flex items-center text-gray-600 justify-center text-[28px] border-opacity-65 rounded-[2pt] duration-700"
                        >
                            {{ AuthChallengeForm.totp[index] ?? '' }}
                        </div>
                    </div>
                </div>
                <div class="flex w-full h-fit">
                    <div class="flex flex-col w-full items-end gap-1.5">
                        <input
                            ref="totp"
                            maxlength="6"
                            type="text"
                            class="bg-transparent h-0 w-0"
                            placeholder="TOTP"
                            @input="this.ValidateInputTOTP()"
                            v-model="AuthChallengeForm.totp"
                        />
                    </div>
                </div>
                <input
                    @click="this.AuthChallengeRequest()"
                    type="submit"
                    class="flex items-center font-medium tracking-wide mx-auto justify-center pt-0.5 focus:outline-0 focus:border-0 focus:bg-opacity-100 text-white text-[11.5px] cursor-pointer duration-500 h-[45px] w-[385px] bg-theme-purple rounded-[8pt]"
                    value="Login"
                />
            </form>
        </transition>
    </div>
</template>

<script>
import {AuthChallenge, InitiateAuth} from "../services/Authentication.js";

export default
{
    data() {
        return {
            InitiateAuthForm: {
                username: null,
                password: null,
            },
            IsLoggingIn: false,
            AuthChallengeForm: {
                totp: '',
            },
            errors: {
                username: false,
                password: false,
                totp: false,
            },
            MenuIsOpen: false,
            Step: 0,
        }
    },

    watch: {
        Step() {
            if (this.Step === 2) {
                setTimeout(() => {
                    this.FocusTOTP();
                }, 500);
            }
        }
    },

    methods: {
        CloseMenu() {
            this.MenuIsOpen = false;
        },

        FocusTOTP() {
            this.$refs.totp.focus();
        },

        ValidateInputTOTP() {
            this.AuthChallengeForm.totp = this.AuthChallengeForm.totp.replace(/\D/g, '');
        },

        AuthChallengeRequest() {
            this.IsLoggingIn = true;
            this.errors.totp = false;
            this.errors.totp_message = null;

            if (!this.AuthChallengeForm.totp) {
                this.errors.totp = true;
                this.errors.totp_message = 'TOTP code is required.';
                this.IsLoggingIn = false
                return;
            }

            AuthChallenge(this.AuthChallengeForm)
                .then((response) => {
                    if (response.status === 200) {
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    this.errors.totp = true;
                    this.errors.totp_message = 'Invalid TOTP code.'
                })
                .then(() => {
                    this.IsLoggingIn = false
                })
        },

        InitiateAuthRequest() {
            this.IsLoggingIn = true;

            this.errors.password = this.errors.username = false;
            this.errors.password_message = this.errors.username_message = null;

            if (!this.InitiateAuthForm.username) {
                this.errors.username = true;
                this.errors.username_message = 'Username is required.'
            }

            if (!this.InitiateAuthForm.password) {
                this.errors.password = true;
                this.errors.password_message = 'Password is required.'
            }

            if (this.errors.password || this.errors.username) {
                this.IsLoggingIn = false
                return;
            }

            InitiateAuth(this.InitiateAuthForm)
                .then((response) => {
                    if (response.status === 200) {
                        this.Step = 2;
                    }
                })
                .catch((error) => {
                    this.errors.username = this.errors.password = true;
                    this.errors.username_message = 'Invalid username.'
                    this.errors.password_message = 'Invalid password.'
                })
                .then(() => {
                    this.IsLoggingIn = false;
                })

        }
    },

    mounted() {
        this.Step = 1;
    }
}
</script>

<style scoped>
.icon-container:hover #menu-icon rect,
.icon-container:hover #menu-icon path {
    fill: #fff;
}

.icon-container:hover {
    background-color: #3D3B8E;
}

.icon-container {
    background-color: #f0f0f0;
    transition: background-color 0.5s ease;
}

.pop-enter-active, .pop-leave-active {
    transition: all 0.5s ease;
}

.pop-enter-from {
    opacity: 0;
    transform: scale(0.8);
}

.pop-enter-to {
    opacity: 1;
    transform: scale(1);
}

.pop-leave-from {
    opacity: 1;
    transform: scale(1);
}

.pop-leave-to {
    opacity: 0;
    transform: scale(0.8);
}
</style>

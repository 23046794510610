import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from "./router/index";
import store from "./store/index.js";
import VClickOutside from "./directives/VClickOutside.js";

createApp(App)
    .use(router)
    .use(store)
    .directive('click-outside', VClickOutside)
    .mount('#app');

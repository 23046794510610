<template>
    <div class="h-[375px] bg-transparent w-full flex relative gap-[14px]">
        <div class="custom-shadow-0 | w-3/5 h-full p-[18px] bg-white rounded-[4pt]">
            <div class="w-full h-full flex flex-col">
                <div class="flex w-full justify-between items-start">
                    <div class="flex flex-col -mt-[4px]">
                        <span class="text-[15px] text-gray-600">Total Account Balance</span>
                        <div v-if="transactions.length > 0" class="min-h-fit pb-[8px] text-[28px] mb-1 tracking-wide text-gray-500 font-medium w-full">$ {{ transactions[0]?.balance }}</div>
                    </div>
                    <div @click="report_view_is_open = !report_view_is_open" class="cursor-pointer w-[155px] h-[28px] text-[10.5px] text-theme-purple flex items-center px-[12px] font-medium tracking-wide relative bg-theme-purple bg-opacity-10 rounded-[4pt]">
                        {{ report_views[selected_report_view] }}
                        <transition name="pop">
                            <div
                                v-if="report_view_is_open"
                                class="pop-box custom-shadow-0 | absolute top-[32px] left-0 flex flex-col rounded-[4pt] w-full"
                            >
                                <div
                                    v-for="(view, view_index) in report_views"
                                    @click="selected_report_view = view_index"
                                    class="top-[32px] left-0 w-full flex items-center text-theme-purple duration-500 text-opacity-75 hover:bg-theme-purple hover:text-white cursor-pointer h-[28px] px-[12px] first-of-type:rounded-t-[4pt] last-of-type:rounded-b-[4pt] bg-[#F5F5F9]"
                                >
                                    {{ view }}
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <transition name="pop">
                    <div v-if="selected_report_view === 0" class="pop-box | h-full flex flex-col">
                        <div class="w-full mb-4">
                            <ul class="flex gap-[12px]">
                                <li
                                    v-for="(timeline, Index) in timeline_selection"
                                    v-show="timeline.disabled === false"
                                    @click="timeline.disabled === false ? context_timeline = Index : ''"
                                    :class="Index === context_timeline ? 'text-theme-purple bg-theme-purple bg-opacity-20' : 'text-gray-400 bg-gray-100 hover:bg-theme-purple hover:bg-opacity-10 hover:text-opacity-80 hover:text-theme-purple'"
                                    class="w-[75px] py-[4px] flex cursor-pointer items-center justify-center rounded-md text-[11.5px] duration-500"
                                >
                                    {{ timeline.label }}
                                </li>
                            </ul>
                        </div>
                        <div class="h-full max-h-full flex-col justify-around pb-[18px] pt-[8px]">
                            <div class="flex h-full w-full flex-col">
                                <div class="w-full h-full flex gap-[8px]">
                                    <div
                                        v-for="(columns, timeline) in timeline_selection[context_timeline].col"
                                        class="w-full bg-[#2BCE7F] rounded-t-[6pt] bg-opacity-15 h-full relative"
                                    >
                                        <div
                                            class="absolute w-full duration-500 ease-in bottom-0 left-0 bg-[#2BCE7F] rounded-t-[6pt] bg-opacity-70"
                                            :style="`height: ${(show_timeline && timeline_transactions_total?.transaction_totals && timeline_transactions_total.transaction_totals.length > 0) ? timeline_transactions_total.transaction_totals[timeline].credit * 100 / timeline_transactions_total?.maximum.credit : '0' }%`"
                                        >
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full h-full flex gap-[8px]">
                                    <div
                                        v-for="(columns, timeline) in timeline_selection[context_timeline].col"
                                        class="w-full bg-[#F03737] rounded-b-[6pt] bg-opacity-15 h-full relative"
                                    >
                                        <div
                                            class="absolute w-full duration-500 ease-in top-0 left-0 bg-[#F03737] bg-opacity-70 rounded-b-[6pt]"
                                            :style="`height: ${(show_timeline && timeline_transactions_total?.transaction_totals && timeline_transactions_total.transaction_totals.length > 0) ? timeline_transactions_total.transaction_totals[timeline].debit * 100 / timeline_transactions_total?.maximum.debit : '0' }%`"
                                        >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full h-[32px] flex mt-[4px] gap-[8px]">
                                <div
                                    v-if="timeline_selection[context_timeline].label !== 'Day'"
                                    v-for="label in timeline_selection[context_timeline].col_labels"
                                    class="pop-box | w-full rounded-[6pt] text-[10.5px] tracking-wide font-medium flex items-center justify-center text-gray-400 h-[24px]"
                                >
                                    {{ label }}
                                </div>
                                <div
                                    v-if="timeline_selection[context_timeline].label === 'Day'"
                                    v-for="(label, Index) in 31"
                                    class="pop-box | w-full rounded-[6pt] text-[10.5px] tracking-wide font-medium flex items-center justify-center text-gray-400 h-[24px]"
                                >
                                    {{ Index + 1 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>

                <transition name="pop">
                    <div v-if="selected_report_view === 1" class="pop-box | h-full flex flex-col gap-[16px]">
                        <div class="flex justify-between items-center text-theme-purple bg-theme-purple bg-opacity-5 pl-[12px] pr-[8px] py-[8px] text-[12px] rounded-[4pt] text-opacity-80">
                            Average Account Balance For {{this.days_of_transactions }} Days
                            <div class="h-[32px] w-[100px] flex items-center text-theme-purple justify-center bg-theme-purple bg-opacity-10 rounded-[2pt]">
                                $ {{ this.avg_balance.average_balance }}
                            </div>
                        </div>
                        <div class="h-full w-full flex relative">
                            <div class="h-full w-full py-[6px]">
                                <div class="w-full h-full border-y border-theme-purple border-opacity-20">
                                </div>
                            </div>
                            <div class="bg-transparent absolute top-0 left-0 flex justify-center items-end h-full w-full">
                                <div
                                    class="relative w-full border-t-2 border-dashed border-red-600 border-opacity-30"
                                    :style="`height: ${ CalculatePercentageBetween(this.avg_balance.max_min[0], this.avg_balance.max_min[1], this.avg_balance.average_balance) }%`"
                                >
                                    <div class="absolute top-[-18px] text-[9.5px] text-red-600 right-0">
                                        {{ this.avg_balance.average_balance }}
                                    </div>
                                </div>
                            </div>
                            <div class="bg-transparent absolute top-0 left-0 py-[6px] -mt-[1.5px] h-full" style="width: calc(100% - 60px)">
                                <div class="w-full h-full flex justify-around items-end">
                                    <div
                                        v-for="daily_balance in this.avg_balance.balance_per_day"
                                        class="relative text-[9.5px] w-[4px]"
                                        :style="`height: ${ CalculatePercentageBetween(this.avg_balance.max_min[0], this.avg_balance.max_min[1], daily_balance) }%`"
                                    >
                                        <div class="absolute top-0 left-0 rounded-full w-[3px] h-[3px] bg-black"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-[65px] h-full flex flex-col items-center justify-between">
                                <div class="w-full text-[9.5px] px-[10px] h-[16px] flex items-center font-semibold text-theme-purple text-opacity-80">{{ this.avg_balance.max_min[1] }}</div>
                                <div class="w-full text-[9.5px] px-[10px] h-[16px] flex items-center font-semibold text-theme-purple text-opacity-80">{{ this.avg_balance.max_min[0] }}</div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="w-2/5 h-full">
            <div class="w-full gap-[18px] h-full flex flex-col">
                <div class="custom-shadow-0 | p-[18px] h-full flex flex-col gap-[16px] bg-white rounded-[4pt]">
                    <div v-for="payment_history in payment_history_report">
                        <div class="h-[33.33%] w-full flex flex-col gap-1">
                            <span class="text-[10.5px] text-gray-500 font-medium">{{ payment_history.label }} ({{ payment_history.count }})</span>
                            <div class="flex gap-[4px]">
                                <div
                                    v-for="(mp, index) in 10"
                                    :style="`background-color: ${index < payment_history.count ? payment_history.color : '#f3f4f6'}`"
                                    class="h-[18px] w-full last-of-type:rounded-r-[4pt] first-of-type:rounded-l-[4pt] bg-gray-100"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default
{
    emits: ['AvgBalance', 'report-count'],

    data() {
        return {
            context_timeline: 0,
            days_of_transactions: 0,
            avg_balance: null,
            report_view_is_open: false,
            report_views: ['Full Report', 'Account Avg.'],
            payment_history_report: {
                NSF: {
                    count: 0,
                    map: [],
                    color: '#F03737',
                    label: 'Non Sufficient Fund',
                    enabled: true,
                },
                OPP: {
                    count: 0,
                    map: [],
                    color: '#3D3B8E',
                    label: 'Payment Oppositions',
                    enabled: true,
                },
                MP: {
                    count: 0,
                    map: [],
                    color: '#11B873',
                    label: 'Micro Loans',
                    enabled: true,
                },
                SALARY: {
                    count: 0,
                    map: [],
                    color: '#11B873',
                    label: 'Salary',
                    enabled: false,
                }
            },
            selected_report_view: 0,
            show_timeline: false,
            categories: [
                {color: '3F82F7', label: 'Bank Fees'},
                {color: '83D0FB', label: 'Purchases'},
                {color: '83D0CF', label: 'Loans'},
                {color: 'B063EB', label: 'E-Transf'},
                {color: '5F5EDC', label: 'Paycheck'},
                {color: 'EA4B62', label: 'Other'}
            ],
            timeline_transactions_total: null,
            timeline_selection: [
                {
                    label: 'Day',
                    col: 31,
                    disabled: false,
                },
                {
                    label: 'Week',
                    col: 7,
                    col_labels: ["SUN", 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
                    disabled: false,
                },
                {
                    label: 'Month',
                    col: 12,
                    col_labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
                    disabled: false,
                },
                {
                    label: 'Year',
                    col: 'custom',
                    disabled: true,
                },
                {
                    label: 'All',
                    col: 1,
                    disabled: true,
                },
            ]
        }
    },

    props: {
        transactions: {
            type: Array,
            default: []
        }
    },

    watch: {
        selected_report_view: {
            handler() {
                if (this.selected_report_view === 1) {
                    this.CalculateAvgBalance();
                } else if (this.selected_report_view === 0) {
                    switch (this.context_timeline)
                    {
                        case 0:
                            this.CalculateTimelineTransactionsDaily();
                            break;
                        case 1:
                            this.CalculateTimelineTransactionsWeekly();
                            break;
                        case 2:
                            this.CalculateTimelineTransactionsMonthly();
                            break;
                        default:
                            window.location.href = '/';
                    }

                    setTimeout(() => {
                        this.show_timeline = true;
                    }, 500);
                }
            },
            deep: true,
        },

        transactions: {
            handler(newVal, oldVal) {
                this.show_timeline = false;

                this.CalculateAvgBalance();
                this.CalculateTransactionsReport();

                switch (this.context_timeline)
                {
                    case 0:
                        this.CalculateTimelineTransactionsDaily();
                        break;
                    case 1:
                        this.CalculateTimelineTransactionsWeekly();
                        break;
                    case 2:
                        this.CalculateTimelineTransactionsMonthly();
                        break;
                    default:
                        window.location.href = '/';
                }

                setTimeout(() => {
                    this.show_timeline = true;
                }, 500);
            },
            deep: true
        },

        context_timeline: {
            handler(newVal, oldVal) {
                this.show_timeline = false;

                switch (this.context_timeline)
                {
                    case 0:
                        this.CalculateTimelineTransactionsDaily();
                        break;
                    case 1:
                        this.CalculateTimelineTransactionsWeekly();
                        break;
                    case 2:
                        this.CalculateTimelineTransactionsMonthly();
                        break;
                    default:
                        window.location.href = '/';
                }

                setTimeout(() => {
                    this.show_timeline = true;
                }, 500);
            },
            deep: true
        }
    },

    methods: {
        CalculateTransactionsReport() {
            const NSF_ID = [1, 65];
            const OPP_ID = [2, 66];
            const MP_ID = [9];
            const SALARY_ID = [3];
            const RTN_NSF = [69, 68];

            this.payment_history_report.NSF.map = this.transactions.filter(transaction => NSF_ID.includes(transaction.transaction_type_id));
            this.payment_history_report.NSF.map_2 = this.transactions.filter(transaction => RTN_NSF.includes(transaction.transaction_type_id));
            this.payment_history_report.NSF.count = this.payment_history_report.NSF.map.length;

            this.payment_history_report.NSF.map_2.forEach((nsf) => {
                if (parseInt(nsf.debit) > 35) {
                    const fees =  [40, 45, 48, 50, 65];
                    fees.forEach(fee => {
                        const amount = parseInt(nsf.debit);
                        if (amount % fee === 0) {
                            this.payment_history_report.NSF.count += (amount / fee);
                        }
                    });
                }
            });

            this.payment_history_report.NSF.map.forEach((nsf) => {
                if ((nsf.description.includes("Frais sur effet sans provisions") || nsf.description.includes("NSF item fee")) && nsf.description.includes("@")) {
                    const nsf_count = parseInt(nsf.description.match(/\b\d+(?=\s@)/)[0]);
                    this.payment_history_report.NSF.count += nsf_count - 1;
                }

                if (parseInt(nsf.debit) > 35) {
                    const fees =  [40, 45, 48, 50, 65];
                    fees.forEach(fee => {
                        const amount = parseInt(nsf.debit);
                        if (amount % fee === 0) {
                            this.payment_history_report.NSF.count += (amount / fee) - 1;
                        }
                    });
                } else {
                    this.payment_history_report.NSF.count -= 1;
                }
            });

            this.payment_history_report.SALARY.map = this.transactions.filter(transaction => SALARY_ID.includes(transaction.transaction_type_id));
            this.payment_history_report.SALARY.count = this.payment_history_report.SALARY.map.length;

            this.payment_history_report.OPP.map = this.transactions.filter(transaction => parseInt(transaction.debit) !== 0 && OPP_ID.includes(transaction.transaction_type_id));
            this.payment_history_report.OPP.count = this.payment_history_report.OPP.map.length;

            this.payment_history_report.MP.map = this.transactions.filter(transaction => (MP_ID.includes(transaction.transaction_type_id) && parseFloat(transaction.debit) !== 0));

            this.payment_history_report.MP.count = this.payment_history_report.MP.map.reduce((acc, transaction) => {
                if (!acc.seen.has(transaction.debit)) {
                    acc.seen.add(transaction.debit);
                    acc.count += 1;
                }
                return acc;
            }, { seen: new Set(), count: 0 }).count;

            this.$emit('report-count',  {SALARY: this.payment_history_report.SALARY, NSF: this.payment_history_report.NSF, OPP: this.payment_history_report.OPP, MP: this.payment_history_report.MP} );
        },

        CalculatePercentageBetween(min, max, value) {
            return ((value - min) / (max - min)) * 100;
        },

        CalculateTimelineTransactionsMonthly() {
            const months = Array(12).fill(0).map(() => ({ debit: 0, credit: 0 }));
            let maxDebit = 0;
            let maxCredit = 0;

            this.transactions.forEach(transaction => {
                const date = new Date(transaction.date * 1000);
                const month = date.getMonth();

                months[month].debit += parseFloat(transaction.debit);
                months[month].credit += parseFloat(transaction.credit);

                if (months[month].debit > maxDebit) maxDebit = months[month].debit;
                if (months[month].credit > maxCredit) maxCredit = months[month].credit;
            });

            this.timeline_transactions_total = {'transaction_totals': months, 'maximum': {debit: maxDebit, credit: maxCredit}};
        },

        CalculateTimelineTransactionsDaily() {
            const days = Array(31).fill(0).map(() => ({ debit: 0, credit: 0 }));
            let maxDebit = 0;
            let maxCredit = 0;

            this.transactions.forEach(transaction => {
                const date = new Date(transaction.date * 1000);
                const day = date.getDate() - 1; // Get the day of the month (1...31) and adjust to 0-based index

                days[day].debit += parseFloat(transaction.debit);
                days[day].credit += parseFloat(transaction.credit);

                if (days[day].debit > maxDebit) maxDebit = days[day].debit;
                if (days[day].credit > maxCredit) maxCredit = days[day].credit;
            });

            this.timeline_transactions_total = {'transaction_totals': days, 'maximum': {debit: maxDebit, credit: maxCredit}};
        },

        CalculateAvgBalance() {
            const filteredTransactions = this.transactions
                .filter(t => t.order === 1 || t.order === "1")
                .sort((a, b) => b.date - a.date);

            const balances = filteredTransactions.map(t => t.balance);
            const averageBalance = balances.reduce((sum, balance) => parseFloat(sum) + parseFloat(balance), 0) / balances.length;

            this.avg_balance = {
                average_balance: averageBalance.toFixed(2),
                balance_per_day: balances,
                max_min: [Math.min(...balances), Math.max(...balances)],
            };

            this.$emit('AvgBalance', this.avg_balance);
        },

        CalculateTimelineTransactionsWeekly() {
            const daysOfWeek = Array(7).fill(0).map(() => ({ debit: 0, credit: 0 }));
            let maxDebit = 0;
            let maxCredit = 0;

            this.transactions.forEach(transaction => {
                const date = new Date(transaction.date * 1000);
                const dayOfWeek = date.getDay();

                console.log(dayOfWeek);

                daysOfWeek[dayOfWeek].debit += parseFloat(transaction.debit);
                daysOfWeek[dayOfWeek].credit += parseFloat(transaction.credit);

                if (daysOfWeek[dayOfWeek].debit > maxDebit) maxDebit = daysOfWeek[dayOfWeek].debit;
                if (daysOfWeek[dayOfWeek].credit > maxCredit) maxCredit = daysOfWeek[dayOfWeek].credit;
            });

            this.timeline_transactions_total = {'transaction_totals': daysOfWeek, 'maximum': {debit: maxDebit, credit: maxCredit}};
        }
    },
}
</script>

<style scoped>
.pop-enter-active, .pop-leave-active {
    transition: all 0.3s ease;
}

.pop-enter-from {
    opacity: 0;
    transform: scale(0.8);
}

.pop-enter-to {
    opacity: 1;
    transform: scale(1);
}

.pop-leave-from {
    opacity: 1;
    transform: scale(1);
}

.pop-leave-to {
    opacity: 0;
    transform: scale(0.8);
}
</style>


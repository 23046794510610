<template>
    <auth-layout>
        <div v-if='!is_loading' class="px-[12px] py-[24px]">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-[14px] font-medium text-gray-600">Instant Bank Verification ({{ bank_accounts.length ?? "0" }})</h1>
                </div>
            </div>
            <div class="mt-[18px] flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="overflow-hidden">
                            <table class="min-w-full divide-y divide-gray-100">
                                <thead class="bg-gray-50">
                                    <tr class="divide-x divide-gray-100">
                                        <th scope="col" class="font-normal py-3.5 pl-4 pr-3 text-left text-[10.5px] text-gray-500 tracking-wide">Client</th>
                                        <th scope="col" class="w-[125px] max-w-[205px] font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Phone Number</th>
                                        <th scope="col" class="w-[95px] max-w-[205px] font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Accounts</th>
                                        <th scope="col" class="w-[125px] max-w-[205px] font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Source</th>
                                        <th scope="col" class="w-fit font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Institution</th>
                                        <th scope="col" class="w-fit max-w-[205px] font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Last Update</th>
                                        <th scope="col" class="w-fit max-w-[205px] font-normal px-3 py-3.5 text-center text-[10.5px] text-gray-500 tracking-wide">Checks</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr
                                        v-for="(Account, Index) in bank_accounts"
                                        :key="Index"
                                        @click="Redirect(Account.id)"
                                        class="cursor-pointer divide-x divide-gray-100 curs"
                                    >
                                        <td class="whitespace-nowrap py-4 px-4 text-[12px] font-normal text-gray-900">
                                            <div class="flex flex-col">
                                                <span class="text-[12px] capitalize">{{ Account?.bank_accounts[0]?.bank_account_holder?.name }}</span>
                                                <span class="text-[10.5px] text-gray-400">ID: {{ Account?.reference_id }}</span>
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap text-center px-3 py-4 text-[10.5px] text-gray-500">{{ `(${Account?.bank_accounts[0]?.bank_account_holder?.phone_regional_code}) ${Account?.bank_accounts[0]?.bank_account_holder?.phone_number}` }}</td>
                                        <td class="whitespace-nowrap text-center px-3 py-4 text-[10.5px] text-gray-500">{{ Account?.bank_accounts.length }}</td>
                                        <td class="whitespace-nowrap text-center px-3 py-4 text-[10.5px] text-gray-500">
                                            <span class="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-[10.5px] font-medium text-blue-700">
                                                {{ Account?.provider }}
                                            </span>
                                        </td>
                                        <td class="whitespace-nowrap px-3 py-4 text-[10.5px] tracking-wide text-center text-gray-500">{{ this.InstitutionName(Account?.bank_accounts[0]?.institution_number) }}</td>
                                        <td class="max-w-[105px] whitespace-nowrap text-center px-3 py-4 text-[10.5px] text-gray-500">
                                            {{
                                                `${new Date(Account.last_refresh * 1000).toISOString().slice(0, 10).replace(/-/g, '-')} ${new Date(Account.last_refresh * 1000).toTimeString().slice(0, 8).replace(/:/g, ':')}`
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </auth-layout>
</template>

<script>
import {RetrieveBankAccounts} from "../services/BankAccount.js";
import AuthLayout from "../components/Common/AuthLayout.vue";
import {Helper} from "../mixins/Helper.js";

export default
{
    mixins: [Helper],

    data() {
        return {
            bank_accounts: [],
            is_loading: false,
        }
    },

    components: {
        AuthLayout
    },

    methods: {
        Redirect(id) {
            window.location.href = `bank-account/${id}`;
        },
    },

    mounted() {
        this.is_loading = true;

        RetrieveBankAccounts(null, null, this.$route.query.page)
            .then(response => {
                this.bank_accounts = response.data.bank_account_clients;
            })
        this.is_loading = false;
    }
}
</script>
